<script setup lang="ts">
import {
    isGuest,
    isVerified,
    mustBeLogin,
    mustBeVerified,
} from "@/Utils/Helpers";

import useEmitter from "@/Composables/useEmitter";
import { ButtonColor, ButtonSize } from "@/Components/Shared/button";
import TheFeedbackForm from "@/Components/App/forms/TheFeedbackForm.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
const { emitter } = useEmitter();

type Props = {
    label?: string;
    buttonColor?: ButtonColor;
    buttonClass?: string;
    buttonSize?: ButtonSize;
};

const {
    label = "Feedback?",
    buttonColor = "primary",
    buttonClass = "fixed bottom-[48px] -right-[36px] z-20 -rotate-90",
    buttonSize = "small",
} = defineProps<Props>();

const openFeedbackModal = () => {
    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    emitter.emit("modal", {
        component: TheFeedbackForm,
        title: "Feedback",
    });
};
</script>

<template>
    <AppButton
        :size="buttonSize"
        :class="buttonClass"
        :color="buttonColor"
        @click="openFeedbackModal"
    >
        {{ label }}
    </AppButton>
</template>
