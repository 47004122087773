<script setup lang="ts">
import {
    computed,
    ref,
    shallowRef,
    onMounted,
    watch,
    onBeforeUnmount,
} from "vue";
import useEmitter from "@/Composables/useEmitter";
import AppModal from "@/Components/Shared/modal/AppModal.vue";
import AppAlert from "@/Components/Shared/alert/AppAlert.vue";

const { emitter } = useEmitter();
const emit = defineEmits(["close", "success"]);

const modal = ref(null);
const baseComponent = shallowRef(null);
const baseTitle = ref("");
const baseComponentProps = ref(null);
const baseCloseOnClick = ref(true);
const baseCloseOnEsc = ref(true);
const baseVariant = ref("");
const baseRedirectOnSuccess = ref("");
const baseShow = ref(false);
const baseAlert = ref("");
const baseOnSuccess = ref(null);
const baseScrollTop = ref(0);
const showBodyOnloadMessage = ref(false);
const success = ref(false);

// computed
const hasComponent = computed(() => {
    return !!baseComponent.value;
});

// watchers
watch(baseShow, value => {
    const el = document.body;
    if (value) {
        // add fixed positioning and remember scrollY position
        baseScrollTop.value = window.scrollY;
        el.style.position = "fixed";
        el.style.width = "100%";
        el.style.top = `-${baseScrollTop.value}px`;
        el.classList.add("stop-scrolling");
        el.classList.add("modal-open");
    } else {
        el.style.removeProperty("position");
        el.style.removeProperty("top");
        el.style.removeProperty("width");
        el.classList.remove("stop-scrolling");
        el.classList.remove("modal-open");
        window.scrollTo(0, baseScrollTop.value);
    }
});

// methods
const beforeUnloadListener = event => {
    event.preventDefault();
    return (event.returnValue =
        "Weet je zeker dat je weg wilt navigeren? Wijzigingen worden mogelijk niet opgeslagen.");
};

const handleOutsideClick = () => {
    if (!baseCloseOnClick.value) return;
    handleClose();
};
const handleCloseForce = () => {
    handleClose();
};
const handleClose = () => {
    if (showBodyOnloadMessage.value && !success.value) {
        if (
            confirm(
                "Weet je zeker dat je weg wilt navigeren? Wijzigingen worden mogelijk niet opgeslagen.",
            )
        ) {
            baseShow.value = false;
            showBodyOnloadMessage.value = false;
            window.removeEventListener("beforeunload", beforeUnloadListener, {
                capture: true,
            });
        }
    } else {
        baseShow.value = false;
        showBodyOnloadMessage.value = false;
        window.removeEventListener("beforeunload", beforeUnloadListener, {
            capture: true,
        });
    }
};
const handleSuccess = () => {
    success.value = true;
    handleClose();
};
const handleKeyup = (e: any) => {
    if (e.keyCode === 27) handleClose();
};

const clickBackground = () => {
    if (!baseCloseOnClick.value) return;
    handleClose();
};

// before unload
const handleBeforeUnload = () => {
    showBodyOnloadMessage.value = true;
    window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
    });
};
const handleCancelBeforeUnload = () => {
    showBodyOnloadMessage.value = false;
    window.removeEventListener("beforeunload", beforeUnloadListener);
};

// import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
// const { activate, deactivate } = useFocusTrap(modal, { immediate: true });

// lifecycle
onMounted(() => {
    showBodyOnloadMessage.value = false;

    emitter.on("modal", (data: any) => {
        baseComponent.value = data.component;
        baseTitle.value = data.title;
        baseComponentProps.value = data.props;

        if (typeof data.closeOnClick !== "undefined") {
            baseCloseOnClick.value = data.closeOnClick;
        }
        if (data.closeOnEsc) {
            baseCloseOnEsc.value = data.closeOnEsc;
        }
        baseVariant.value = data.modalClass;
        if (data.redirectOnSuccess) {
            baseRedirectOnSuccess.value = data.redirectOnSuccess;
        }
        baseAlert.value = data.alert;
        baseShow.value = true;

        // add event listener on modal active
        document.addEventListener("keyup", handleKeyup);
    });
});
onBeforeUnmount(() => {
    emitter.off("modal");
    document.removeEventListener("keyup", handleKeyup);
    const el = document.body;
    el.style.removeProperty("position");
    el.style.removeProperty("top");
    el.classList.remove("stop-scrolling");
    el.classList.remove("modal-open");

    showBodyOnloadMessage.value = false;
    window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
    });
});
</script>

<template>
    <teleport to="body">
        <div
            v-show="baseShow"
            class="fixed inset-0 flex items-start justify-center p-4 bg-gray-800 bg-opacity-50 z-50"
            role="dialog"
            @mousedown.self="clickBackground"
        >
            <transition
                enter-active-class="transition ease-out duration-300"
                enter-from-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to-class="transform opacity-100 translate-y-0 sm:scale-100"
                leave-active-class="transition ease-in duration-50"
                leave-from-class="transform opacity-100 translate-y-0 sm:scale-100"
                leave-to-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <AppModal
                    :key="`modal-${baseTitle}`"
                    ref="modal"
                    v-model="baseShow"
                    :variant="baseVariant"
                    :title="baseTitle"
                    :persistent="!baseCloseOnClick"
                    @close="handleClose"
                    @success="handleSuccess"
                    @on-close-force="handleCloseForce"
                >
                    <AppAlert
                        v-if="baseAlert"
                        class="mb-8"
                        size="large"
                        icon-size="small"
                        variant="warning"
                    >
                        {{ baseAlert }}
                    </AppAlert>

                    <component
                        :is="baseComponent"
                        v-bind="baseComponentProps"
                        @close="handleClose"
                        @success="handleSuccess"
                        @before-unload="handleBeforeUnload"
                        @cancel-before-unload="handleCancelBeforeUnload"
                    />
                </AppModal>
            </transition>
        </div>
    </teleport>
</template>
