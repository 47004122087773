<script setup lang="ts">
import { ref, onMounted } from "vue";
import { uniqueId } from "lodash-es";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppContainer, {
    ContainerSize,
} from "@/Components/Shared/container/AppContainer.vue";
import AppHeader from "@/Components/Shared/header/AppHeader.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";

export type ModalVariant = "full" | "inline" | "large";

type Props = {
    persistent?: boolean;
    modelValue: boolean;
    notPrintable?: boolean;
    notClosable?: boolean;
    title?: string;
    variant?: ModalVariant;
    containerSize?: ContainerSize;
};
const {
    variant = undefined,
    containerSize = undefined,
    modelValue = false,
    notPrintable = false,
    notClosable = false,
    title = undefined,
} = defineProps<Props>();
const emit = defineEmits([
    "update:modelValue",
    "close",
    "beforeUnload",
    "cancelBeforeUnload",
]);
const id = ref("");

const classes: AntlerClasses<Props> = {
    base: "relative flex flex-col w-full max-w-lg max-h-screen max-w-[600px] lg:max-w-[800px] sm:my-9 m-auto overflow-auto bg-white drop-shadow-2xl rounded-lg",
    variants: {
        variant: {
            full: "max-w-7xl lg:max-w-none sm:my-2",
            large: "max-w-7xl lg:max-w-7xl sm:my-2",
            inline: "",
        },
    },
};

const { aClass } = installAntlerComponent("modal", { variant }, classes);

// lifecycle
onMounted(() => {
    id.value = uniqueId();
});
</script>

<template>
    <div
        v-if="modelValue"
        :id="`modal-${id}`"
        :class="aClass()"
        aria-modal="true"
        role="document"
        tabindex="-1"
        :aria-describedby="`model-${id}-body`"
        :aria-labelledby="`model-${id}-header`"
    >
        <AppHeader
            :id="`model-${id}-header`"
            :class="[
                'sticky flex-row top-0 left-0 z-20 flex items-center pl-4 pr-1 py-1 rounded-t border-b border-gray-100',
                { 'bg-blue-100': title },
            ]"
            spacing="none"
            variant="row"
        >
            <AppTitle
                v-if="$slots.title || title"
                size="xsmall"
                weight="medium"
            >
                <slot
                    v-if="$slots.title"
                    name="title"
                />
                <template v-else-if="title">
                    {{ title }}
                </template>
            </AppTitle>

            <AppButton
                v-if="!notClosable"
                ref="modalClose"
                aria-label="Close dialog"
                class="ml-auto"
                icon="cross"
                variant="ghost round"
                @click.prevent="$emit('close')"
            />
        </AppHeader>

        <AppContainer :size="variant === 'full' ? '' : containerSize">
            <div
                :id="`model-${id}-body`"
                ref="modalBody"
                class="flex flex-col grow pt-4 pb-12 sm:px-4 md:px-12"
            >
                <slot />
            </div>
        </AppContainer>

        <footer
            v-if="$slots.footer"
            class="p-4 bg-gray-50"
        >
            <slot name="footer" />
        </footer>
    </div>
</template>
