<script setup lang="ts">
import Cookies from "js-cookie";
import { computed, onMounted, ref } from "vue";
import AppArticle from "@/Components/Shared/article/AppArticle.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";

const showCookieConsent = ref(false);

// methods
const acceptCookies = () => {
    Cookies.set("tcgers_cookies", true, { expires: 14 });
    showCookieConsent.value = false;
};
const denyCookies = () => {
    Cookies.set("tcgers_cookies", false, { expires: 14 });
    showCookieConsent.value = false;
};

// computed
const hasCookies = computed(() => {
    return Cookies.get("tcgers_cookies");
});

onMounted(() => {
    if (!hasCookies.value) {
        showCookieConsent.value = true;
    }
});
</script>

<template>
    <div
        v-if="showCookieConsent && !hasCookies"
        class="fixed bottom-2 left-2 z-40 flex p-4 w-full max-w-[220px] bg-primary rounded-lg shadow-2xl"
    >
        <AppArticle
            size="small"
            class="text-white"
        >
            <p class="mb-xsmalls">
                We geven om jouw gegevens en we gebruiken cookies alleen om
                ervaring te verbeteren.
                <a
                    href="/cookies"
                    class="text-white"
                    >Meer weten?</a
                >
            </p>
            <AppButtonGroup
                size="large"
                variant="column"
            >
                <AppButton
                    size="full"
                    @click="acceptCookies"
                >
                    Prima! 🍪
                </AppButton>
                <AppButton
                    size="full"
                    @click="denyCookies"
                >
                    Nee, bedankt.
                </AppButton>
            </AppButtonGroup>
        </AppArticle>
    </div>
</template>
