<script setup lang="ts">
import { apiPathUrl } from "@/Utils/Helpers";
import { computed, reactive, ref } from "vue";
import AppFormGroup from "@/Components/Shared/forms/AppFormGroup.vue";
import AppFormInputTextarea from "@/Components/Shared/forms/AppFormInputTextarea.vue";
import AppFormFooter from "@/Components/Shared/forms/AppFormFooter.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppForm from "@/Components/Shared/forms/AppForm.vue";
import { toast } from "vue-sonner";

const emit = defineEmits(["close", "success"]);

const form = reactive({
    description: "",
});
const loading = ref(false);
const errors = ref([]);

// computed
const goodToGo = computed(() => {
    return form.description && !loading.value;
});

// methods
const prepareFormData = () => {
    const formData = new FormData();

    formData.append("description", form.description);
    formData.append("url", window.location.href);
    return formData;
};

const handleSubmit = () => {
    loading.value = true;

    // set form data
    const formData = prepareFormData();

    // create recommendation
    axios
        .post(apiPathUrl("/feedbacks/create"), formData)
        .then(() => {
            // show flash
            toast.success("Jouw feedback is verzonden! Bedankt!");

            form.description = "";
            emit("success");
        })
        .catch((error: any) => {
            // set errors
            errors.value = error.response.data.errors;
            toast.error("Er ging iets mis bij het aanmaken van de feedback");
        })
        .finally(() => {
            // set loading to false
            loading.value = false;
            emit("close");
        });
};
</script>

<template>
    <AppForm @submit.prevent="handleSubmit">
        <AppFormGroup>
            <AppFormInputTextarea
                v-model="form.description"
                autosize
                :min-height="100"
                :errors="errors.description"
                hint="Beschrijf zo duidelijk mogelijk, zoals bijv. de locatie en de handeling. De huidige URL wordt meegezonden."
                label="Beschrijving"
                name="description"
            />
        </AppFormGroup>

        <AppFormFooter>
            <AppButtonGroup>
                <AppButton
                    type="submit"
                    :disabled="!goodToGo"
                    :loading="loading"
                    color="primary"
                    class="z-20"
                >
                    Verstuur feedback
                </AppButton>
            </AppButtonGroup>
        </AppFormFooter>
    </AppForm>
</template>
