<template>
    <Toaster
        :toast-options="{
            style: {
                fontSize: '16px',
                gap: '8px',
                borderWidth: '2px',
            },
        }"
        close-button
    />
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { toast, Toaster } from "vue-sonner";

const handleMessages = (messages: any) => {
    if (Array.isArray(messages)) {
        messages.forEach(m => {
            if (m.text) {
                toast[m.type](m.text);
            }
        });
    } else if (messages && messages.text) {
        toast[messages.type](messages.text);
    }
};

// lifecycle
onMounted(() => {
    if (usePage().props.flash && usePage().props.flash.messages) {
        handleMessages(usePage().props.flash.messages);
    }
});

// watcher
if (usePage().props.flash) {
    watch(
        () => usePage()?.props.flash.messages,
        value => {
            handleMessages(value);
        },
    );
}
</script>
