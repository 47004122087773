<script setup lang="ts">
import { installAntlerComponent } from "@/Utils/component";

export type LayoutAs = "div" | "main";
export type LayoutVariant =
    | "app"
    | "root"
    | "admin"
    | "auth"
    | "appMain"
    | "appSidebarLeft"
    | "appSidebar";

const {
    variant = undefined,
    as = "div",
    showMessage = false,
} = defineProps<{
    variant?: LayoutVariant;
    as?: LayoutAs;
    showMessage?: boolean;
}>();

const classes = {
    base: "flex flex-col grow",
    variants: {
        variant: {
            app: "lg:ml-[220px]",
            admin: "sm:flex-row grow",
            auth: "flex-row sm:min-h-screen",
            root: "flex flex-col min-h-full",
            appMain: "h-full xl:h-[calc(100vh-68px)] mt-[50px] sm:mt-[70px]",
            appSidebarLeft: "md:ml-[220px]",
            appSidebar: "xl:flex-row xl:overflow-auto",
        },
    },
};
const { aClass } = installAntlerComponent("layout", { variant }, classes);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
    </component>
</template>
